// todo VERY IMPORTANT IF YOU EDIT THIS FILE, you also need to edit the files in /src/deploy

// for hosting our acct creation page on a new subdomain, study. we need these to be able to handle
// markeing site pages
// local: http://127.0.0.1:3002
// dev: https://www.speakfluentlytoday.com
// prod: https://www.languageconvo.com
const rootUrl = 'https://www.languageconvo.com';

const envSpecificData = {
    // not for any specific product, this is our overall var
    environment: {
        // prod: prod
        // dev: dev
        // local: local
        env: 'prod',
    },
    // for hosting our acct creation page on a new subdomain, study. we need these to be able to handle
    // markeing site pages
    newpages: {
        home: `${rootUrl}`,
        howitworks: `${rootUrl}/en/how-it-works/`,
        prices: `${rootUrl}/en/prices/`,
        contact: `${rootUrl}/en/contact/`,
        mission: `${rootUrl}/en/our-mission/`,
        faq: `${rootUrl}/en/faq/`,
        privacypolicy: `${rootUrl}/en/privacy-policy/`,
        terms: `${rootUrl}/en/terms-of-service/`,
    },
    // the base of the url we're on
    baseUrlName: {
        // local: 127.0.0.1:3001
        // dev: speakfluentlytoday
        // prod: languageconvo
        url: 'languageconvo',
    },
    // the url of the legacy php app. this is used to make ajax calls to it
    //  and to redirect user if they try to go to root domain page
    appUrl: {
        // local: https://127.0.0.1:8000/studenthome
        // dev: https://study.speakfluentlytoday.com/studenthome
        // prod: https://study.languageconvo.com/studenthome
        url: 'https://study.languageconvo.com/studenthome',
    },
    newappUrl: {
        // local: https://127.0.0.1:4007/home
        // dev: https://app.speakfluentlytoday.com/home
        // prod: https://app.languageconvo.com/home
        home: 'https://app.languageconvo.com/home',
    },
    recaptcha: {
        // prod: 6LekVSsUAAAAAExErqfAYTztH8Q8AaKTW0AP15J6
        // dev: 6LeNVisUAAAAAGqWDTpV2xT4dp0pNVlN0hBvkf5V
        apiKey: '6LekVSsUAAAAAExErqfAYTztH8Q8AaKTW0AP15J6',
    },
    facebook: {
        // prod: 420264691499160
        // dev: 918402088540782
        pixelId: '420264691499160',
    },
    googleAnalytics: {
        // prod: UA-31192074-1
        // dev: UA-151108834-1
        trackingId: 'UA-31192074-1',
    },
    sentry: {
        // prod: prod
        // dev: dev
        // local: local
        environment: 'prod',
        // local, dev, prod: https://8182309a777143859569f2b5132f84b1@sentry.io/258481
        uid: 'https://8182309a777143859569f2b5132f84b1@sentry.io/258481',
    },
    atatus: {
        // prod: 4725c0c11e744ff885ced037834ab3e3
        // dev: 4cd2da81fe4b4c149d6beb71a9b34eee
        // local: f9a5d16551564f949934b8a18261a371
        uid: '4725c0c11e744ff885ced037834ab3e3',
    },
    rudderstack: {
        // dev: 2JMfoNms9LukgXzBYJGaKqehQnW
        // prod: 1lRFPFRBcDAG9qNbe0XAYtjEaSG
        writeKey: '1lRFPFRBcDAG9qNbe0XAYtjEaSG',
    },
};

module.exports = envSpecificData;
