import nunj from 'nunjucks';
import queryString from 'query-string';
import ui from '../modules/ui';
import signup from '../domain/signup';
import userSettings from '../domain/userSettings';
import languages from '../modules/languages';
import cookieManager from '../modules/cookieManager';
import localSettings from '../modules/localSettings';
import { siteData } from '../../../data/index';
import logErr from '../modules/logErr';
import perfMon from '../modules/perfMon';
import tagManager from '../modules/tagManager';

let elMainContent;
let elFormSubmit;
let elFormSubmitProcessing;
let elFormFieldInvalidNotification;
let elFormCreateAccount;
let elFormSetupLoading;
let elFormSetupError;

// alerts at top of the page for user giving us their email
let elThanksWeWillContact;
let elThanksAlreadyContacted;

// required for signup
let elLangLearn;
let elWhoLearning;
let elWhyLearn;
let elEmail;
let elName;
let elGroup;
let elTermsPrivacyCheck;
// optional inputs
let elPreferedTeacherGender;
let elLearningGoal;
let elCurrentLevel;
let elTargetLevel;
let elWantTextbook;
let elWantHomework;
let elStudentAge;
let elShortOrLongtermGoals;
let elLessonPace;
let elPreferedTeachingStyle;
let elIntrestedCountry;
let elLanguageGoals;
let elFeelImmersion;
// errors
let elErrorGeneralAjax;
let elErrorAccountAlreadyExists;
// other
let elMktEmailModal;

// tells us if form submittal is in process
let formSubmissionInProcess;
let forwardUrl;

// nunjucks environment
const nunjEnv = nunj.configure('', { autoescape: true });

function publicInit() {
    _initVars();
    _initListeners();
    _initFunctionality();
}

function _initVars() {
    elFormSubmit = '[data-js="formSubmit"]';
    elFormSubmitProcessing = '[data-js="formSubmitProcessing"]';
    elMainContent = '[data-js="mainContent"]';
    elFormFieldInvalidNotification = '[data-js="formFieldInvalidNotification"]';
    elFormCreateAccount = '[data-js="formCreateAccount"]';
    elFormSetupLoading = '[data-js="formSetupLoading"]';
    elFormSetupError = '[data-js="formSetupError"]';

    // alerts at top of the page for user giving us their email
    elThanksWeWillContact = '[data-js="thanksWeWillContact"]';
    elThanksAlreadyContacted = '[data-js="thanksAlreadyContacted"]';

    // required for signup
    elLangLearn = '[data-js="langlearn"]';
    elWhoLearning = '[data-js="whoLearning"]';
    elWhyLearn = '[data-js="whyLearn"]';
    elEmail = '[data-js="email"]';
    elName = '[data-js="name"]';
    elGroup = '[data-js="group"]';
    elTermsPrivacyCheck = '[data-js="termsPrivacyCheck"]';
    // optional inputs
    elPreferedTeacherGender = '[data-js="preferedTeacherGender"]';
    elLearningGoal = '[data-js="learningGoal"]';
    elCurrentLevel = '[data-js="currentLevel"]';
    elTargetLevel = '[data-js="targetLevel"]';
    elWantTextbook = '[data-js="wantTextbook"]';
    elWantHomework = '[data-js="wantHomework"]';
    elStudentAge = '[data-js="studentAge"]';
    elShortOrLongtermGoals = '[data-js="shortOrLongtermGoals"]';
    elLessonPace = '[data-js="lessonPace"]';
    elPreferedTeachingStyle = '[data-js="preferedTeachingStyle"]';
    elIntrestedCountry = '[data-js="intrestedCountry"]';
    elLanguageGoals = '[data-js="languageGoals"]';
    elFeelImmersion = '[data-js="feelImmersion"]';
    // errors
    elErrorGeneralAjax = '[data-js="errorGeneralAjax"]';
    elErrorAccountAlreadyExists = '[data-js="errorAccountAlreadyExists"]';
    // other
    elMktEmailModal = '[data-js="mktEmailModal"]';

    formSubmissionInProcess = false;
}

function _initListeners() {
    _listenFormSubmit();
    _listenForChangesToRequiredValues();
    _listenLangChange();
    _listenWhoLearningChange();
    _listenWhyLearnChange();
    _listenGroupChange();
}

function _initFunctionality() {
    // load time for signup form
    perfMon.start(1);

    // get the email cookie
    const currentEmailCookie = cookieManager.getMarketingEmail();

    // if there is a query string, that means we sent the user here from a contact form. show
    // them a success message
    const parsed = queryString.parse(window.location.search);
    if (parsed.ref) {
        const reffrom = parsed.ref;
        if (reffrom === 'contact') {
            if (currentEmailCookie !== false) {
                $(elMktEmailModal).html(currentEmailCookie);
            }
            ui.show(elThanksWeWillContact);
        } else if (reffrom === 'contactPast') {
            if (currentEmailCookie !== false) {
                $(elMktEmailModal).html(currentEmailCookie);
            }
            ui.show(elThanksAlreadyContacted);
        }
    }

    // set up the freetrial form. this gets answer choices from the db, for the user_settings table
    // then injects them into the dom
    userSettings.getUserSettingOptions()
        .then((dt) => {
            if (dt.gotAnswers === true) {
                // populate answer dropdowns
                _displayAnswers(dt.answers);

                // region get cookies, and select options in dropdowns

                // language learning cookie
                const currentLangLearnCookie = cookieManager.getLangLearnFrontend();
                if (currentLangLearnCookie !== false) {
                    $(elLangLearn).val(currentLangLearnCookie);
                }

                // who is learning cookie
                const currentWhoLearningCookie = cookieManager.getWhoLearning();
                if (currentWhoLearningCookie !== false) {
                    $(elWhoLearning).val(currentWhoLearningCookie);
                }

                // why learning cookie
                const currentWhyLearnCookie = cookieManager.getWhyLearn();
                if (currentWhyLearnCookie !== false) {
                    $(elWhyLearn).val(currentWhyLearnCookie);
                }

                // group cookie
                const currentGroupCookie = cookieManager.getGroup();
                if (currentGroupCookie !== false) {
                    $(elGroup).val(currentGroupCookie);
                }

                // email cookie; we already got it above, now set dropdown if val exists
                if (currentEmailCookie !== false) {
                    $(elEmail).val(currentEmailCookie);
                }
                // endregion

                // hide loading, show form
                ui.hide(elFormSetupLoading);
                ui.makeVisible(elFormCreateAccount);

                // load time for signup form
                perfMon.end(1);
            } else {
                // load time for signup form
                perfMon.end(1);

                logErr.msg('IMPORTANT: an error occurred loading free trial form for a user!');
                ui.hide(elFormSetupLoading);
                ui.show(elFormSetupError);
            }
        })
        .catch((e) => {
            logErr.err(e);
            ui.hide(elFormSetupLoading);
            ui.show(elFormSetupError);

            // load time for signup form
            perfMon.fail(1);
        });
}

function _displayAnswers(allAnswers) {
    allAnswers.forEach((element) => {
        if (element.questionUid === 'whoLearning') {
            const output = nunjEnv.render('b4/free-trial/selectAnswers.nunj', {
                answers: element.answers,
            });
            $(elWhoLearning).html(output);
        } else if (element.questionUid === 'whyLearn') {
            const output = nunjEnv.render('b4/free-trial/selectAnswers.nunj', {
                answers: element.answers,
            });
            $(elWhyLearn).html(output);
        } else if (element.questionUid === 'learningGoal') {
            const output = nunjEnv.render('b4/free-trial/selectAnswers.nunj', {
                answers: element.answers,
            });
            $(elLearningGoal).html(output);
        } else if (element.questionUid === 'currentLevel') {
            const output = nunjEnv.render('b4/free-trial/selectAnswers.nunj', {
                answers: element.answers,
            });
            $(elCurrentLevel).html(output);
        } else if (element.questionUid === 'targetLevel') {
            const output = nunjEnv.render('b4/free-trial/selectAnswers.nunj', {
                answers: element.answers,
            });
            $(elTargetLevel).html(output);
        } else if (element.questionUid === 'wantTextbook') {
            const output = nunjEnv.render('b4/free-trial/selectAnswers.nunj', {
                answers: element.answers,
            });
            $(elWantTextbook).html(output);
        } else if (element.questionUid === 'wantHomework') {
            const output = nunjEnv.render('b4/free-trial/selectAnswers.nunj', {
                answers: element.answers,
            });
            $(elWantHomework).html(output);
        } else if (element.questionUid === 'feelImmersion') {
            const output = nunjEnv.render('b4/free-trial/selectAnswers.nunj', {
                answers: element.answers,
            });
            $(elFeelImmersion).html(output);
        } else if (element.questionUid === 'lessonPace') {
            const output = nunjEnv.render('b4/free-trial/selectAnswers.nunj', {
                answers: element.answers,
            });
            $(elLessonPace).html(output);
        } else if (element.questionUid === 'preferedTeachingStyle') {
            const output = nunjEnv.render('b4/free-trial/selectAnswers.nunj', {
                answers: element.answers,
            });
            $(elPreferedTeachingStyle).html(output);
        } else if (element.questionUid === 'shortOrLongtermGoals') {
            const output = nunjEnv.render('b4/free-trial/selectAnswers.nunj', {
                answers: element.answers,
            });
            $(elShortOrLongtermGoals).html(output);
        }
    });
}

// region listeners

// listen submit form
function _listenFormSubmit() {
    $(elMainContent).on('click', elFormSubmit, (event) => {
        // stop the default behavior of forms, which is to send the user to a new page
        event.preventDefault();
        event.stopPropagation();

        if (formSubmissionInProcess === false) {
            // we'll set this to false if any inputs are invalid
            let valid = true;

            // hide any previous form submission error main message boxes
            ui.hide(elFormFieldInvalidNotification);
            ui.hide(elErrorGeneralAjax);

            // required values for signup. validate that they are entered
            const data = {
                userSettings: {},
            };
            const frontendLanguageId = parseInt($(elLangLearn).val(), 10);
            data.userSettings.whoLearning = parseInt($(elWhoLearning).val(), 10);
            data.userSettings.whyLearn = parseInt($(elWhyLearn).val(), 10);
            // important!
            // may 5, 2023 we hardcoded this to make the signup form simpler
            data.group = 1;
            const eml = $(elEmail).val();
            data.email = eml.trim();
            const nm = $(elName).val();
            data.name = nm.trim();
            const terms = $(elTermsPrivacyCheck).is(':checked');
            if (!frontendLanguageId || !data.userSettings.whoLearning
                || !data.userSettings.whyLearn || !data.email || !data.name || !data.group
                || !terms) {
                valid = false;
            }

            // if we have values for each required input, now get the backend language id this
            // user wants to study
            if (valid === true) {
                const langData = languages.frontendLanguageIdConvert(frontendLanguageId);

                // todo this next section is VERY important, and maps to some server side fields.
                //  note we are hardcoding two things here which directly map to the server side.
                //  the question uid 'spanishSpecialQuestion1' and 'spanishSpecialQuestion1'. as
                //  well as the db values, 1 and 2
                if (langData.frontendId === 1) {
                    // spanish, latin american
                    data.userSettings.spanishSpecialQuestion1 = 1;
                } else if (langData.frontendId === 2) {
                    // spanish, european
                    data.userSettings.spanishSpecialQuestion1 = 2;
                } else if (langData.frontendId === 8) {
                    // portuguese, brazilian
                    data.userSettings.portugueseSpecialQuestion1 = 1;
                } else if (langData.frontendId === 9) {
                    // portuguese, european
                    data.userSettings.portugueseSpecialQuestion1 = 2;
                }

                // if we found the backend language id, great, that's what we want to pass to
                // the server. if we didn't....we don't want to continue. we log this error in
                // module languages, it should really not ever happen
                if (langData.success === true) {
                    data.lang = langData.backendId;
                } else {
                    valid = false;
                }
            }

            // if we have valid inputs, ajax to create account
            if (valid === true) {
                // form submission is in process
                formSubmissionInProcess = true;
                // hide any error message texts we manually set
                _removeInvalidFromAllRequiredFields();
                // set the email cookie
                cookieManager.setMarketingEmail(data.email);

                // region get the optional values
                if ($(elLearningGoal).val()) {
                    data.userSettings.learningGoal = parseInt($(elLearningGoal).val(), 10);
                }

                if ($(elPreferedTeacherGender).val()) {
                    data.teacherGender = parseInt($(elPreferedTeacherGender).val(), 10);
                }

                if ($(elCurrentLevel).val()) {
                    data.userSettings.currentLevel = parseInt($(elCurrentLevel).val(), 10);
                }

                if ($(elTargetLevel).val()) {
                    data.userSettings.targetLevel = parseInt($(elTargetLevel).val(), 10);
                }

                if ($(elWantTextbook).val()) {
                    data.userSettings.wantTextbook = parseInt($(elWantTextbook).val(), 10);
                }

                if ($(elWantHomework).val()) {
                    data.userSettings.wantHomework = parseInt($(elWantHomework).val(), 10);
                }

                if ($(elStudentAge).val()) {
                    data.userSettings.studentAge = parseInt($(elStudentAge).val(), 10);
                }

                if ($(elFeelImmersion).val()) {
                    data.userSettings.feelImmersion = parseInt($(elFeelImmersion).val(), 10);
                }

                if ($(elShortOrLongtermGoals).val()) {
                    data.userSettings
                        .shortOrLongtermGoals = parseInt($(elShortOrLongtermGoals).val(), 10);
                }

                if ($(elLessonPace).val()) {
                    data.userSettings.lessonPace = parseInt($(elLessonPace).val(), 10);
                }

                if ($(elPreferedTeachingStyle).val()) {
                    data.userSettings
                        .preferedTeachingStyle = parseInt($(elPreferedTeachingStyle).val(), 10);
                }

                if ($(elIntrestedCountry).val()) {
                    data.userSettings.intrestedCountry = parseInt($(elIntrestedCountry).val(), 10);
                }

                // only set about me if after trimmed, it has some content
                if ($(elLanguageGoals).val()) {
                    const fullAnswer = $(elLanguageGoals).val();
                    const trimmedFullAnswer = fullAnswer.trim();
                    if (trimmedFullAnswer) {
                        data.userSettings.languageGoals = $(elLanguageGoals).val();
                    }
                }

                // endregion

                // show the form submittal in process button
                ui.hide(elFormSubmit);
                ui.show(elFormSubmitProcessing);

                // attempt to create an account, set session cookie, and log the user in
                signup.createAccount(data)
                    .then((dt) => {
                        // form submission no longer in process
                        formSubmissionInProcess = false;

                        // if the user's account was sucessfully created, great! forward the user
                        // to the main account home page
                        if (dt.accountCreated === true) {
                            // fire our account created tag conversion event
                            tagManager.convAccountCreated();

                            // redirect user to the new react app home page
                            forwardUrl = siteData.envSpecificData.newappUrl.home;
                            forwardUrl = `${forwardUrl}?acctcrt=1`;
                            // set language cookies
                            localSettings.language(frontendLanguageId);
                            // set the mktEmail cookie, this is the marketing site email cookie
                            cookieManager.setMarketingEmail(data.email);
                            // set the 'emllogin' cookie, the one used for login. this is
                            // *different* than the marketing email cookie
                            cookieManager.setBackendemail(data.email);
                            window.location.href = forwardUrl;

                            // account with this email address already exists
                        } else if (dt.error === 1) {
                            // set this back to true, so that we don't allow the user to resubmit
                            // the form somehow
                            formSubmissionInProcess = true;
                            // hide the submit buttons, show notif that the user
                            // already has an account
                            ui.hide(elFormSubmitProcessing);
                            ui.hide(elFormSubmit);
                            ui.show(elErrorAccountAlreadyExists);

                        // language id validation failed
                        } else if (dt.error === 2) {
                            // set the form field to invalid
                            ui.setFormFieldInvalid(elLangLearn);

                            // reshow the submit button
                            ui.hide(elFormSubmitProcessing);
                            ui.show(elFormSubmit);

                            // show the form invalid notif
                            ui.show(elFormFieldInvalidNotification);

                            // who is learning validation failed
                        } else if (dt.error === 3) {
                            // set the form field to invalid
                            ui.setFormFieldInvalid(elWhoLearning);

                            // reshow the submit button
                            ui.hide(elFormSubmitProcessing);
                            ui.show(elFormSubmit);

                            // show the form invalid notif
                            ui.show(elFormFieldInvalidNotification);

                            // why learning validation failed
                        } else if (dt.error === 4) {
                            // set the form field to invalid
                            ui.setFormFieldInvalid(elWhyLearn);

                            // reshow the submit button
                            ui.hide(elFormSubmitProcessing);
                            ui.show(elFormSubmit);

                            // show the form invalid notif
                            ui.show(elFormFieldInvalidNotification);

                            // group validation failed
                        } else if (dt.error === 5) {
                            // set the form field to invalid
                            ui.setFormFieldInvalid(elGroup);

                            // reshow the submit button
                            ui.hide(elFormSubmitProcessing);
                            ui.show(elFormSubmit);

                            // show the form invalid notif
                            ui.show(elFormFieldInvalidNotification);

                            // name validation failed
                        } else if (dt.error === 6) {
                            // set the form field to invalid
                            ui.setFormFieldInvalid(elName);

                            // reshow the submit button
                            ui.hide(elFormSubmitProcessing);
                            ui.show(elFormSubmit);

                            // show the form invalid notif
                            ui.show(elFormFieldInvalidNotification);

                            // email address validation failed
                        } else if (dt.error === 7) {
                            // set the form field to invalid
                            ui.setFormFieldInvalid(elEmail);

                            // reshow the submit button
                            ui.hide(elFormSubmitProcessing);
                            ui.show(elFormSubmit);

                            // show the form invalid notif
                            ui.show(elFormFieldInvalidNotification);

                            // a general error occurred
                        } else if (dt.error === 9) {
                            // reshow the submit button
                            ui.hide(elFormSubmitProcessing);
                            ui.show(elFormSubmit);

                            // show general error please try again
                            ui.show(elErrorGeneralAjax);

                            const msg = `a general free trial form submission error occurred for this user: ${data.email}`;
                            logErr.msg(msg);

                            // this should not occur, error code should always be returned in ajax
                            // but if for some reason it doesn't, we handle that here
                        } else {
                            // reshow the submit button
                            ui.hide(elFormSubmitProcessing);
                            ui.show(elFormSubmit);

                            // show general error please try again
                            ui.show(elErrorGeneralAjax);

                            const msg = `this should never occur. an unknown free trial form submission error occurred for this user: ${data.email}`;
                            logErr.msg(msg);
                        }
                    })
                    .catch((e) => {
                        // log the error
                        logErr.err(e);

                        // form submission no longer in process
                        formSubmissionInProcess = false;

                        // reshow the submit button
                        ui.hide(elFormSubmitProcessing);
                        ui.show(elFormSubmit);

                        // show general error please try again
                        ui.show(elErrorGeneralAjax);
                    });

                // else show error
            } else {
                ui.show(elFormFieldInvalidNotification);
                ui.formAttemptedValidation(elFormCreateAccount);
            }
        }
    });
}

// listen for changes to any of the required values; if one changes, then hide the notification
// box so that user is not confused
function _listenForChangesToRequiredValues() {
    $(elMainContent).on('click', elLangLearn, () => {
        ui.hide(elFormFieldInvalidNotification);
        ui.removeFormFieldInvalid(elLangLearn);
    });
    $(elMainContent).on('click', elWhoLearning, () => {
        ui.hide(elFormFieldInvalidNotification);
        ui.removeFormFieldInvalid(elWhoLearning);
    });
    $(elMainContent).on('click', elWhyLearn, () => {
        ui.hide(elFormFieldInvalidNotification);
        ui.removeFormFieldInvalid(elWhyLearn);
    });
    $(elMainContent).on('click', elEmail, () => {
        ui.hide(elFormFieldInvalidNotification);
        ui.removeFormFieldInvalid(elEmail);
    });
    $(elMainContent).on('click', elName, () => {
        ui.hide(elFormFieldInvalidNotification);
        ui.removeFormFieldInvalid(elName);
    });
    $(elMainContent).on('click', elGroup, () => {
        ui.hide(elFormFieldInvalidNotification);
        ui.removeFormFieldInvalid(elGroup);
    });
    $(elMainContent).on('click', elTermsPrivacyCheck, () => {
        ui.hide(elFormFieldInvalidNotification);
        ui.removeFormFieldInvalid(elTermsPrivacyCheck);
    });
}

// changes to the dropdowns which we want to change cookies vals for
function _listenLangChange() {
    // changes to the top hero
    $(elMainContent).on('change', elLangLearn, (event) => {
        const stringLangId = $(event.currentTarget).val();
        // convert to an int, if there is a value. no value means user selected the empty option
        if (stringLangId) {
            const langId = parseInt(stringLangId, 10);
            localSettings.language(langId);
        }
    });
}
function _listenWhoLearningChange() {
    $(elMainContent).on('change', elWhoLearning, (event) => {
        const stringWhoLearning = $(event.currentTarget).val();
        // convert to an int, if there is a value. no value means user selected the empty option
        if (stringWhoLearning) {
            const whoLearningVal = parseInt(stringWhoLearning, 10);
            localSettings.whoLearning(whoLearningVal);
        }
    });
}
function _listenWhyLearnChange() {
    $(elMainContent).on('change', elWhyLearn, (event) => {
        const stringWhyLearn = $(event.currentTarget).val();
        // convert to an int, if there is a value. no value means user selected the empty option
        if (stringWhyLearn) {
            const whyLearnVal = parseInt(stringWhyLearn, 10);
            localSettings.whyLearn(whyLearnVal);
        }
    });
}
function _listenGroupChange() {
    $(elMainContent).on('change', elGroup, (event) => {
        const stringGroup = $(event.currentTarget).val();
        // convert to an int, if there is a value. no value means user selected the empty option
        if (stringGroup) {
            const groupVal = parseInt(stringGroup, 10);
            localSettings.group(groupVal);
        }
    });
}

// endregion

function _removeInvalidFromAllRequiredFields() {
    ui.removeFormFieldInvalid(elLangLearn);
    ui.removeFormFieldInvalid(elWhoLearning);
    ui.removeFormFieldInvalid(elWhyLearn);
    ui.removeFormFieldInvalid(elEmail);
    ui.removeFormFieldInvalid(elName);
    ui.removeFormFieldInvalid(elGroup);
}

export default publicInit;
