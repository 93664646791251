// region error logger

// our error logger. we break convention regarding importing, and initialize our error logger
// here first so that we ensure any errors that occur during imports are caught
import logErr from './modules/logErr';

// initialize the error logger, has to be done first to catch errors in any of imports
logErr.init();

// endregion

// region performance monitor

// disable the eslint rule that requires all imports to occur first
/* eslint-disable import/first */
import perfMon from './modules/perfMon';

// initialize our performance monitor
perfMon.init();

// endregion

// region imports

import tagManager from './modules/tagManager';
import utmRecords from './modules/utmRecords';

// allow jquery to be imported after our error logger and site data
// eslint-disable-next-line import/order
import jQuery from 'jquery';

// allow bootstrap to be 'unused', and allow it to be imported after our error logger and site data
// you can test that bootstrap js is working correctly by testing a modal, they require the js
// eslint-disable-next-line no-unused-vars, import/order
import bootstrap from 'bootstrap';

// all our globally available (window.*) functions and vars. this is for things like google
// recaptcha callback function
import './modules/globals';

// region pages

// home
import p1 from './pages/index';
// free-trial
import p2 from './pages/freeTrial';
import p3 from './pages/howItWorks';
import p4 from './pages/prices';
// p5 is faq
// p6 is our-mission
// p7 is contact
import p7 from './pages/contact';
// p8 is old marketing careers page
// p9 is terms-of-service
// p10 is privacy policy
// p11 is 404
// p12 is fun-language-facts

// endregion

/* eslint-enable import/first */

// endregion

// make jquery available globally
window.$ = jQuery;
window.jQuery = jQuery;

// controller here that will check to see what page has loaded
$(document).ready(() => {
    // get the current page we are on. every page in our app must have this value
    const currentPage = $('[data-js="theCurrentPage"]').data('current-page');

    switch (currentPage) {
    case 'p1': {
        p1();
        break;
    }
    case 'p2': {
        p2();
        break;
    }
    case 'p3': {
        p3();
        break;
    }
    case 'p4': {
        p4();
        break;
    }
    case 'p5': {
        break;
    }
    case 'p6': {
        break;
    }
    case 'p7': {
        p7();
        break;
    }
    case 'p9': {
        break;
    }
    case 'p10': {
        break;
    }
    case 'p11': {
        break;
    }
    case 'p12': {
        break;
    }
    default: {
        // log a message that no case was found
        logErr.msg('no case was found for app.js');
    }
    }
});

// get tag data from cookies, then init and pageview for our tags. includes facebook, google
// analytics, etc. note that unlinke app, where we call this from inside document.ready, we can
// call this here bc we don't need to ever call any ajax
const tagData = tagManager.getTgDataFromCookies();
tagManager.initAndPageView(tagData);

// parse utm params
utmRecords.setUtmRecord();
