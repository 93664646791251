import cookieManager from '../modules/cookieManager';
import localSettings from '../modules/localSettings';
import ui from '../modules/ui';
import { siteData } from '../../../data/index';

let elMainContent;
let elLangLearn;
let elPricesDisplay;
let elPackage;

function publicInit() {
    _initVars();
    _initListeners();
    _initFunctionality();
}

function _initVars() {
    elMainContent = '[data-js="mainContent"]';
    elLangLearn = '[data-js="langLearn"]';
    elPricesDisplay = '[data-js="pricesDisplay"]';
    elPackage = '[data-js="package"]';
}

function _initFunctionality() {
    // hardcode initial language to the first in the list. then attempt to get language from
    // the user's cookies. if it exists, we'll use that to display price
    let currentLangId = siteData.prices[0].langIdFrontend;
    const currentLangLearnCookie = cookieManager.getLangLearnFrontend();
    if (currentLangLearnCookie !== false) {
        // show this lang in the dropdown
        $(elLangLearn).val(currentLangLearnCookie);
        currentLangId = currentLangLearnCookie;
    }

    // hardcode package to 1 (20 hour package), so that we always show it on pageload
    const currentPackageId = 1;
    _getAndDisplayPrice(currentLangId, currentPackageId);

    // after we've determined the language, set it, and set the price, make both visible
    ui.makeVisible(elLangLearn);
    ui.makeVisible(elPricesDisplay);
}

function _initListeners() {
    _listenLangChange();
    _listenPackageChange();
}

// changes to the top language dropdown
function _listenLangChange() {
    $(elMainContent).on('change', elLangLearn, (event) => {
        const stringLangId = $(event.currentTarget).val();
        const stringPackageId = $(elPackage).val();
        const langId = parseInt(stringLangId, 10);
        const packageId = parseInt(stringPackageId, 10);
        _getAndDisplayPrice(langId, packageId);
        localSettings.language(langId);
    });
}

function _listenPackageChange() {
    $(elMainContent).on('change', elPackage, (event) => {
        const stringPackageId = $(event.currentTarget).val();
        const packageId = parseInt(stringPackageId, 10);
        const stringLangId = $(elLangLearn).val();
        const langId = parseInt(stringLangId, 10);
        _getAndDisplayPrice(langId, packageId);
    });
}

function _getAndDisplayPrice(langId, packageId) {
    const priceText = _getPrice(langId, packageId);
    _displayPrice(priceText);
}

function _getPrice(langId, packageId) {
    let priceText = '';
    siteData.prices.forEach((element) => {
        if (element.langIdFrontend === langId) {
            // 20 hour package
            if (packageId === 1) {
                priceText = element.price30Mins20Hr;

                // 10 hour package
            } else if (packageId === 2) {
                priceText = element.price30Mins10Hr;

                // 5 hour package
            } else if (packageId === 3) {
                priceText = element.price30Mins5Hr;

                // 2 hour package
            } else if (packageId === 4) {
                priceText = element.price30Mins2Hr;
            }
        }
    });

    return priceText;
}

function _displayPrice(priceText) {
    $(elPricesDisplay).html(priceText);
}

export default publicInit;
