const prices = [
    {
        langIdFrontend: 1, // spanish, latin american
        price30Mins20Hr: '8.40',
        price60Mins20Hr: '16.75',
        price30Mins10Hr: '8.65',
        price60Mins10Hr: '17.25',
        price30Mins5Hr: '8.90',
        price60Mins5Hr: '17.75',
        price30Mins2Hr: '9.15',
        price60Mins2Hr: '18.25',
    },
    {
        langIdFrontend: 2, // spanish, european
        price30Mins20Hr: '8.40',
        price60Mins20Hr: '16.75',
        price30Mins10Hr: '8.65',
        price60Mins10Hr: '17.25',
        price30Mins5Hr: '8.90',
        price60Mins5Hr: '17.75',
        price30Mins2Hr: '9.15',
        price60Mins2Hr: '18.25',
    },
    {
        langIdFrontend: 3, // french
        price30Mins20Hr: '8.40',
        price60Mins20Hr: '16.75',
        price30Mins10Hr: '8.65',
        price60Mins10Hr: '17.25',
        price30Mins5Hr: '8.90',
        price60Mins5Hr: '17.75',
        price30Mins2Hr: '9.15',
        price60Mins2Hr: '18.25',
    },
    {
        langIdFrontend: 4, // german
        price30Mins20Hr: '9.90',
        price60Mins20Hr: '19.75',
        price30Mins10Hr: '10.15',
        price60Mins10Hr: '20.25',
        price30Mins5Hr: '10.40',
        price60Mins5Hr: '20.75',
        price30Mins2Hr: '10.65',
        price60Mins2Hr: '21.25',
    },
    {
        langIdFrontend: 5, // italian
        price30Mins20Hr: '9.40',
        price60Mins20Hr: '18.75',
        price30Mins10Hr: '9.90',
        price60Mins10Hr: '19.75',
        price30Mins5Hr: '10.40',
        price60Mins5Hr: '20.75',
        price30Mins2Hr: '10.90',
        price60Mins2Hr: '21.75',
    },
    {
        langIdFrontend: 6, // japanese
        price30Mins20Hr: '9.90',
        price60Mins20Hr: '19.75',
        price30Mins10Hr: '10.15',
        price60Mins10Hr: '20.25',
        price30Mins5Hr: '10.40',
        price60Mins5Hr: '20.75',
        price30Mins2Hr: '10.65',
        price60Mins2Hr: '21.25',
    },
    {
        langIdFrontend: 7, // mandarin
        price30Mins20Hr: '8.40',
        price60Mins20Hr: '16.75',
        price30Mins10Hr: '8.65',
        price60Mins10Hr: '17.25',
        price30Mins5Hr: '8.90',
        price60Mins5Hr: '17.75',
        price30Mins2Hr: '9.15',
        price60Mins2Hr: '18.25',
    },
    {
        langIdFrontend: 8, // portuguese
        price30Mins20Hr: '10.40',
        price60Mins20Hr: '20.75',
        price30Mins10Hr: '10.65',
        price60Mins10Hr: '21.25',
        price30Mins5Hr: '10.90',
        price60Mins5Hr: '21.75',
        price30Mins2Hr: '11.15',
        price60Mins2Hr: '22.25',
    },
    {
        langIdFrontend: 9, // portuguese
        price30Mins20Hr: '10.40',
        price60Mins20Hr: '20.75',
        price30Mins10Hr: '10.65',
        price60Mins10Hr: '21.25',
        price30Mins5Hr: '10.90',
        price60Mins5Hr: '21.75',
        price30Mins2Hr: '11.15',
        price60Mins2Hr: '22.25',
    },
    {
        langIdFrontend: 10, // arabic
        price30Mins20Hr: '8.40',
        price60Mins20Hr: '16.75',
        price30Mins10Hr: '8.65',
        price60Mins10Hr: '17.25',
        price30Mins5Hr: '8.90',
        price60Mins5Hr: '17.75',
        price30Mins2Hr: '9.15',
        price60Mins2Hr: '18.25',
    },
    {
        langIdFrontend: 11, // russian
        price30Mins20Hr: '8.90',
        price60Mins20Hr: '17.75',
        price30Mins10Hr: '9.15',
        price60Mins10Hr: '18.25',
        price30Mins5Hr: '9.40',
        price60Mins5Hr: '18.75',
        price30Mins2Hr: '9.65',
        price60Mins2Hr: '19.25',
    },
    {
        langIdFrontend: 12, // latin
        price30Mins20Hr: '9.90',
        price60Mins20Hr: '19.75',
        price30Mins10Hr: '10.15',
        price60Mins10Hr: '20.25',
        price30Mins5Hr: '10.40',
        price60Mins5Hr: '20.75',
        price30Mins2Hr: '10.65',
        price60Mins2Hr: '21.25',
    },
];

module.exports = prices;
