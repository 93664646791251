const envSpecificData = require('../envSpecificData');

const urlBase = '/';
const urlBaseLang = `${urlBase}en/`;

const url = {
    base: urlBase,
    baseLang: urlBaseLang,
    built: `${urlBase}builtmarketing/`,
    images: `${urlBase}images/marketing/`,
    // todo VERY IMPORTANT if you edit/update the favicon, you also need to copy/paste it to
    //  the application/src/assets/root folder, so that we have a favicon at the / root
    favicon: `${urlBase}images/marketing/logo3/favicon3/`,
    logo: `${urlBase}images/marketing/logo3/`,
    facebook: 'https://www.facebook.com/LanguageConvo/',
    pages: {
        home: urlBaseLang,
        login: `https://app.${envSpecificData.baseUrlName.url}.com${urlBase}home/`,
        forgotpassword: `${urlBase}studenthome/?nts-panel=anon/forgot_password/`,
        blog: 'https://blog.languageconvo.com',
        prices: `${urlBaseLang}prices/`,
        freetrial: `${urlBaseLang}free-trial/`,
        howitworks: `${urlBaseLang}how-it-works/`,
        faq: `${urlBaseLang}faq/`,
        contact: `${urlBaseLang}contact/`,
        mission: `${urlBaseLang}our-mission/`,
        careers: 'http://careers.languageconvo.com',
        privacypolicy: `${urlBaseLang}privacy-policy/`,
        terms: `${urlBaseLang}terms-of-service/`,
    },
};

module.exports = url;
